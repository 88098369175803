<script>
import MonitorCard from "./MonitorCardToAssign.vue";
export default {
  components: {
    MonitorCard,
  },
  data() {
    return {
      isListMonitor: false,
      search: "",
      show: true,
    };
  },
  computed: {
    screen() {
      return this.$store.state.screen;
    },
    isMoreTablet() {
      return this.$store.state.screen.width >= 768;
    },
    myModels() {
      return this.$store.getters["connectedUsers/getModelsWithMonitor"];
    },
    monitors() {
      let list = this.$store.getters["connectedUsers/getMonitors"] || [];
      if (this.search) {
        list = list.filter((e) => e.user.toUpperCase().includes(this.search.toUpperCase()));
      }
      list = list
        .map((monitor) => ({ ...monitor, myModels: this.myModels.filter((model) => model.monitorIdTemp === monitor._id).length }))
        .sort((a, b) => {
          if (a._id === this.$userData._id) return -1;
          if (a.myModels > b.myModels) return -1;
        });
      return list;
    },
  },
  watch: {
    "$store.state.screen.width"(val) {
      this.show = val >= 768;
    },
  },
  beforeMount() {
    this.show = this.isMoreTablet;
  },
};
</script>

<template>
  <div class="monitorsList gcard">
    <div class="monitorsList__header">
      <div class="monitorsList__title">
        <i
          :class="'fa ' + (!show ? 'fa-solid fa-chevron-down' : 'fa-solid fa-chevron-up')"
          v-if="!isMoreTablet"
          @click="!isMoreTablet && (show = !show)"
        ></i>
        <span class="monitorsList__titleName" @click="!isMoreTablet && (show = !show)">MONITORES</span>
        <span class="monitorsList__amount">{{ monitors.length }}</span>
      </div>
      <div class="monitorsList__searchContainer" v-if="(monitors || []).length || search">
        <input :class="['monitorsList__search', search && 'monitorsList__search--isSearch']" type="text" id="search" name="search" v-model="search" />
        <iconic class="monitorsList__searchIcon" name="search-solid" />
      </div>
    </div>
    <div class="monitorsList__body">
      <div class="monitorsList__list fade-in" v-if="(monitors || []).length && show">
        <MonitorCard
          class="monitorsList__card"
          :user="monitor"
          :isListMonitor="isListMonitor"
          v-for="(monitor, idx) in monitors"
          :key="monitor._id"
        />
      </div>
      <div class="monitorsList__list" v-else-if="!(monitors || []).length && search">
        <p>Ningún monitor coincide con la búsqueda.</p>
      </div>
      <div class="histories__list" v-else-if="show">
        <p>Sin monitores disponibles.</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.monitorsList {
  &__header {
    @include Flex(row, space-between);
    font-family: $sec_font;
  }
  &__title {
    @include Flex(row);
    gap: 10px;
    color: $primary-color;
  }
  &__titleName {
    font-size: 24px;
  }
  &__searchContainer {
    @include Flex(row, flex-end);
    position: relative;
  }
  &__search {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 25px;
    background-color: #5b59572b;
    color: black;
    font-size: 20px;
    transition: 0.7s ease-in-out;
    z-index: 15;
    &:hover,
    &:focus,
    &--isSearch {
      width: 100%;
      padding: $mpadding $mpadding * 1.5;
    }
  }
  &__searchIcon {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 17px;
    z-index: 10;
  }
  &__amount {
    padding: 0 $mpadding/1.5;
    font-size: 20px;
    background-color: $primary-color;
    color: white;
    border-radius: 5px;
  }
  &__card {
    margin-top: $mpadding;
  }
  @media screen and (min-width: $tablet-width) {
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      background-color: $alto;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $chicago;
    }
  }
}
</style>
