<script>
export default {
  components: { HistorieCard: () => import("./HistorieCard.vue") },
  data() {
    return {
      isLoading: false,
      histories: [],
      show: true,
    };
  },
  computed: {
    screen() {
      return this.$store.state.screen;
    },
    isMoreTablet() {
      return this.$store.state.screen.width >= 768;
    },
  },
  watch: {
    "$store.state.screen.width"(val) {
      this.show = val >= 768;
    },
  },
  methods: {
    async getHistories() {
      this.isLoading = true;
      const { data } = await this.$axios.get(`/helpdesk/histories?status=await&sort=up`);
      if (!(data.histories || []).length) return;
      this.histories = data.histories;
      this.isLoading = false;
    },
  },
  beforeMount() {
    this.getHistories();
    this.show = this.isMoreTablet;
  },
};
</script>

<template>
  <div class="histories gcard">
    <div class="histories__header">
      <div class="histories__title" @click="!isMoreTablet && (show = !show)">
        <i :class="'fa ' + (!show ? 'fa-solid fa-chevron-down' : 'fa-solid fa-chevron-up')" v-if="!isMoreTablet"></i>
        <span class="histories__titleName">HISTORIAS PENDIENTES</span>
        <span class="histories__amount">{{ (histories || []).length }}</span>
      </div>
      <div class="histories__contentIcon" v-if="!isLoading" @click="getHistories">
        <iconic class="histories__icon" name="reload" />
      </div>
      <div class="histories__contentIcon histories__contentIcon--spinner" v-else>
        <div class="spinner"></div>
      </div>
    </div>
    <div class="histories__body">
      <div class="histories__list" v-if="isLoading"></div>
      <div class="histories__list fade-in" v-else-if="(histories || []).length && show">
        <HistorieCard class="histories__card" :historie="historie" v-for="historie in histories" :key="historie._id" />
      </div>
      <div class="histories__list" v-else-if="show">
        <p>Sin historias disponibles.</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.histories {
  .spinner {
    width: 20px;
    height: 20px;
    margin-top: 0;
  }
  &__header {
    @include Flex(row, space-between);
    font-family: $sec_font;
  }
  &__title {
    @include Flex(row);
    gap: 10px;
    color: $primary-color;
  }
  &__titleName {
    font-size: 24px;
  }
  &__contentIcon {
    @include Flex(row);
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
      background: $alto;
    }
    &--spinner {
      cursor: auto;
      &:hover {
        background: none;
      }
    }
  }
  &__icon {
    font-size: 1.3em;
  }
  &__card {
    margin-top: $mpadding;
  }
  &__amount {
    padding: 0 $mpadding/1.5;
    font-size: 20px;
    background-color: $primary-color;
    color: white;
    border-radius: 5px;
  }
  @media screen and (min-width: $tablet-width) {
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      background-color: $alto;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $chicago;
    }
  }
}
</style>
