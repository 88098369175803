<script>
import ModelsList from "./ModelsList.vue";
import MonitorsList from "./MonitorsList";
import ListHistories from "./ListHistories.vue";
export default {
  components: { ModelsList, MonitorsList, ListHistories },
};
</script>

<template>
  <div class="assignments">
    <ModelsList class="assignments__cardModels" />
    <div class="assignments__container">
      <MonitorsList class="assignments__cardMonitors" />
      <ListHistories class="assignments__cardHistories" />
    </div>
  </div>
</template>

<style lang="scss">
.assignments {
  width: 100%;
  height: calc(100vh - 150px);
  max-width: $tv_width;
  gap: $mpadding;
  &__container,
  &__cardHistories {
    margin-top: $mpadding;
  }
  @media screen and (min-width: $tablet-width) {
    @include Flex(row, space-between, flex-start);
    &__container {
      width: 60%;
      height: 100%;
      margin-top: 0;
      display: grid;
      grid-template-rows: repeat(2, minmax(50%, 50%));
    }
    &__cardModels {
      height: 100%;
      width: 40%;
    }
  }
}
</style>
