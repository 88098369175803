<script>
import ModelCard from "./ModelCard";
export default {
  components: {
    ModelCard,
    DuplicateModelsList: () => import("@/components/modals/DuplicateModels.vue"),
  },
  data() {
    return {
      search: "",
      showModal: false,
      show: true,
    };
  },
  computed: {
    screen() {
      return this.$store.state.screen;
    },
    isMoreTablet() {
      return this.$store.state.screen.width >= 768;
    },
    models() {
      const models = this.$store.getters["connectedUsers/getModelsInRoom"] || [];
      models.sort((a, b) => {
        if (a.monitorIdTemp && !b.monitorIdTemp) return 1;
        if (!a.monitorIdTemp && b.monitorIdTemp) return -1;
        return a.artisticName.localeCompare(b.artisticName);
      });
      if (this.search) {
        return models.filter((e) => e.artisticName.toUpperCase().includes(this.search.toUpperCase()));
      }
      return models;
    },
    duplicateModels() {
      const search = this.models.reduce((acc, model) => {
        acc[model?.roomTemp?.roomName || model.room] = ++acc[model?.roomTemp?.roomName || model.room] || 0;
        return acc;
      }, {});

      const duplicates = this.models.filter((model) => {
        return search[model?.roomTemp?.roomName || model.room];
      });
      return duplicates.sort((a, b) => (a?.roomTemp?.roomName || a.room).localeCompare(b?.roomTemp?.roomName || b.room));
    },
    isAdmin() {
      return ["superadmin", "coordinator"].includes(this.$userData.role);
    },
  },
  watch: {
    "$store.state.screen.width"(val) {
      this.show = val >= 768;
    },
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
  },
  beforeMount() {
    this.show = this.isMoreTablet;
  },
};
</script>

<template>
  <div class="modelsCard gcard">
    <DuplicateModelsList v-if="duplicateModels.length && showModal" :closeModal="closeModal" />
    <div class="modelsCard__header">
      <div class="modelsCard__title">
        <i
          :class="'fa ' + (!show ? 'fa-solid fa-chevron-down' : 'fa-solid fa-chevron-up')"
          v-if="!isMoreTablet"
          @click="!isMoreTablet && (show = !show)"
        ></i>
        <span class="modelsCard__titleName" @click="!isMoreTablet && (show = !show)">MODELOS</span>
        <span :class="['modelsCard__amount', isAdmin && 'modelsCard__amount--duplicate']" @click="isAdmin ? (showModal = true) : ''">
          {{ models.length }}
        </span>
      </div>
      <div class="modelsCard__searchContainer" v-if="(models || []).length || search">
        <input :class="['modelsCard__search', search && 'modelsCard__search--isSearch']" type="text" id="search" name="search" v-model="search" />
        <iconic class="modelsCard__searchIcon" name="search-solid" />
      </div>
    </div>
    <div class="modelsCard__body">
      <div class="modelsCard__list fade-in" v-if="(models || []).length && show">
        <ModelCard class="modelsCard__card" :user="model" v-for="(model, idx) in models" :key="model._id + idx" />
      </div>
      <div class="modelsCard__list" v-else-if="!(models || []).length && search">
        <p>Ningúna modelo coincide con la búsqueda.</p>
      </div>
      <div class="histories__list" v-else-if="show">
        <p>Sin modelos disponibles.</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.modelsCard {
  &__header {
    @include Flex(row, space-between);
    font-family: $sec_font;
  }
  &__title {
    @include Flex(row);
    gap: 10px;
    color: $primary-color;
  }
  &__titleName {
    font-size: 24px;
  }
  &__amount {
    padding: 0 $mpadding/1.5;
    font-size: 20px;
    background-color: $primary-color;
    color: white;
    border-radius: 5px;
    &--duplicate {
      cursor: pointer;
    }
  }
  &__searchContainer {
    @include Flex(row, flex-end);
    position: relative;
  }
  &__search {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 25px;
    background-color: #5b59572b;
    color: black;
    font-size: 20px;
    transition: 0.7s ease-in-out;
    z-index: 15;
    &:hover,
    &:focus,
    &--isSearch {
      width: 100%;
      padding: $mpadding $mpadding * 1.5;
    }
  }
  &__searchIcon {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 17px;
    z-index: 10;
  }
  &__card {
    margin-top: $mpadding;
  }
  @media screen and (min-width: $tablet-width) {
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      background-color: $alto;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $chicago;
    }
  }
}
</style>
